import React, { useMemo } from 'react'
import { Box, Button } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import moment from 'moment'

const Table = ({ columns, dataExport, data, initialState, user, rowAction, isLoading, enableGrouping = false }) => {
  const cols = useMemo(() => columns, [user],)

  const handleExportRows = (rows) => {
    const fileName = 'PLD_Filtrado'.concat(moment().format('DD-MM-YYYY')).concat('.xls');
    const fileType = 'application/vnd.ms-excel';
    const table = document.createElement('table');
    table.innerHTML = '<thead><tr><th>No. Instrumento</th><th>Fecha instrumento</th><th>Tipo de aviso</th><th>Fecha de aviso</th><th>No. de referencia</th><th>Archivado</th></tr></thead>';
    const tbody = document.createElement('tbody');
    rows.forEach((instrument) => {
      const row = document.createElement('tr');
      row.innerHTML = `<td>${instrument.original.number}</td><td>${moment(instrument.original.date).format('YYYY MM DD')}</td><td>${instrument.original.pld.type}</td><td>${moment(instrument.original.pld.noticeDate).format('YYYY MM DD')}</td><td>${instrument.original.pld.reference}</td><td>${instrument.original.pld.archived ? 'Si' : 'No'}</td>`;
      tbody.appendChild(row);
    });
    table.appendChild(tbody);
    document.body.appendChild(table);
    table.style.display = 'none';
    const blob = new Blob([table.outerHTML], {
      type: fileType
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <MaterialReactTable
      columns={cols}
      data={data ?? []}
      state={{ isLoading: isLoading }}
      enableStickyHeader
      enableStickyFooter
      enableGrouping={enableGrouping}
      muiTableContainerProps={{ sx: { maxHeight: '450px' } }}
      localization={MRT_Localization_ES}
      renderTopToolbarCustomActions={({ table }) => (

        dataExport !== undefined && dataExport ?
          dataExport :

          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Exportar
            </Button>
          </Box>
      )}
      initialState={initialState}
      enableRowActions={!!rowAction}
      renderRowActions={rowAction}
      positionActionsColumn="last"
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200],
      }}
      enableFilterMatchHighlighting
    />
  )
}

export default Table
