import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '../../common/materia-reac-table/table'
import moment from 'moment/moment'
import { generate_workbook } from '../../../utils/js2xlsx'
import { saveAs } from 'file-saver'
import { Box, Stack } from '@mui/material'

class ProvidersTable extends Component {

  columns = [
    {
      header: 'ID',
      accessorKey: 'numberBox',
      Cell: ({ row }) => <Link
        to={(this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-box')) ? `/boxes/${row.original.boxId}` : `#`}>
        {String(row.original.numberBox)}
      </Link>,
      size: 70,
    },
    {
      header: 'Concepto',
      accessorKey: 'concept',
    },
    {
      header: 'Cliente',
      accessorKey: 'client',
    },
    {
      header: 'Tipo',
      accessorKey: 'type',
    },
    {
      header: 'Total',
      accessorKey: 'total',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, table }) => (
        <>
          Total por {' '}
          {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
          <Box
            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
          >
            {cell.getValue().toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        </>
      ),
      Cell: ({ cell }) => cell.getValue().toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      Footer: () => (
        <Stack>
          Importe Total:
          <Box color="warning.main">
            {this.props.sumTotal?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        </Stack>
      ),
    },
    {
      header: 'Estatus',
      accessorKey: 'status',
      size: 50,
      Cell: ({ cell, row }) => {
        return (
            <select className="form-control" value={row.original.status} onChange={(e) => this.props.handleChange(e, row.original)}>
              <option value="No Pagado">No Pagado</option>
              <option value="Parcialmente Pagado">Parcialmente Pagado</option>
              <option value="Pagado">Pagado</option>
            </select>
        )
      },
      enableGrouping: false,
    }
  ]

  handleExport = async (expenses, columns) => {
    const filename = `Pago-Proveedores${(moment().format('YYYYMMDDHHmmss'))}.xlsx`
    let rows = []
    const cols = columns.map(col => {
      col.title = col.header
      col.width = 25
      return col
    })
    expenses.map((element) => {
      let data = []
      columns.map(col => data.push(element[col.accessorKey]))
      rows.push(data)
    })

    saveAs(
      await generate_workbook({
        sheets: [{
          name: 'Pago a Proveedores',
          cols: cols,
          data: rows,
        }],
        props: {
          Title: 'Pago a Proveedores',
          CreatedDate: new Date(),
        },
        type: 'blob',
      }),
      filename,
    )
  }

  render () {
    const { expenses, user } = this.props
    return (user && this.columns ?
        <Table
          columns={this.columns}
          data={expenses}
          user={user}
          enableGrouping={true}
          dataExport={(user && user.role && user.role.actions.some(a => a.method === 'export-box') && (
              <button
                onClick={() => this.handleExport(expenses, this.columns)}
                className="btn btn-success float-right"
              >
                <i className="fa fa-download" aria-hidden="true"/><span> </span>
                Exportar
              </button>)
          )}
        /> : <></>
    )
  }
}

export default ProvidersTable
