import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "../common/form";
import Invoices from "./components/invoices";
import Fees from "./components/fees";
import Expenses from "./components/expenses";
import Payments from "./components/payments";
import Calculator from "../common/calculator";
import {
  getBox,
  getBoxes,
  saveBox,
  notificationBox,
} from "../../services/boxService";
import {
  getClients,
  saveClient,
} from "../../services/clientService";
import {
  getOriginator,
  getOriginators,
} from "../../services/originatorService";
import { getUser, getUsers } from "../../services/userService";
import { getAllStatus } from "../../services/statusService";
import { getTypes } from "../../services/typeService";
import { getPayments } from "../../services/paymentService";
import { getConfigurations } from "../../services/configurationService";
import {
  getInstrument,
  getInstruments,
} from "../../services/instrumentService";
import { getValuations } from "../../services/valuationService";
import { getCurrentUser } from "../../services/authService";
import { postBoxtestimonio } from "../../services/notificationService";
import Autocomplete from "react-autocomplete";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { getMediations } from "../../services/mediationService";
import { currencyFormat } from "../../utils/currency";
import { Multiselect } from "multiselect-react-dropdown";
import { Testimonies } from "./components/testimonies";
import { ObjectID } from "bson";

class BoxForm extends Form {
  state = {
    data: {
      numberBox: 0,
      invoices: [],
      fees: [],
      expenses: [],
      total: 0,
      totalFees: 0,
      revenue: 0,
      commission: 0,
      comChecker: 0,
      instructionInvoice: "",
      payments: [],
      statusId: "",
      items: [],
      description: "",
      paymentComplete: false,
      testimonies: [],
      isMissingInvoiceData: false,
      isExcludeFees: false,
      excludeFeesNotes: "",
      user: {},
      clientId: '',
      checkerId: '',
    },
    numbers: [],
    clients: [],
    originators: [],
    users: [],
    types: [],
    errors: {},
    invoiceTotal: 0,
    invoiceIVA: 0,
    sumInvoices: 0,
    sumInvoicesIVA: 0,
    paymentTotal: 0,
    sumPayments: 0,
    sumFees: 0,
    sumFeesIVA: 0,
    sumExpenses: 0,
    sumExpensesIva: 0,
    configurations: "",
    display: false,
    client: "",
    currencyUSD: 0,
    instrumentsMissing: [],
    status: "",
    focusedCreatedAt: false,
    isStatusChanged: false,
    isEditable: true,
    instrument: {},
    total: 0,
    totalIva: 0,
    isNew: false,
    isLoadingClient: false,
    boxes: [],
  };

  schema = {
    _id: Joi.string(),
    numberBox: Joi.number().required().min(0).label("ID"),
    typeId: Joi.string().required().label("Tipo"),
    itemId: Joi.string().label("Numero"),
    testimonies: Joi.array().optional(),
    clientId: Joi.string().required().label("Cliente"),
    originatorId: Joi.string().required().label("Originador"),
    userId: Joi.string().required().label("Responsable"),
    checkerId: Joi.string().label("Revisor").optional().allow(""),
    feeIds: Joi.string().label("Honorarios"),
    expenseIds: Joi.string().label("Gastos"),
    total: Joi.number().required().label("Total"),
    revenue: Joi.any().label("CP8"),
    commission: Joi.string().label("Comisiones"),
    comChecker: Joi.string().label("Comision Revisor"),
    paymentDate: Joi.string().label("Fecha promesa / Pago"),
    instructionInvoice: Joi.string().label("Instr. Factura"),
    statusId: Joi.string().required().label("Estatus"),
    description: Joi.string().allow("").label("Descripción"),
    isExcludeFees: Joi.boolean(),
    excludeFeesNotes: Joi.string().allow("").label("Notas"),
    items: Joi.when("typeId", {
      is: "5e922954e5b40f5c3097209c",
      then: Joi.array().label("Número").items(Joi.any().required()),
      otherwise: Joi.when("typeId", {
        is: "5ea3191dc7f4f52381c20a88",
        then: Joi.array().label("Número").items(Joi.any().required()),
        otherwise: Joi.allow("").label("First Name"),
      }),
    }),
    user: Joi.object().optional(),
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    delete errors.comChecker;
    delete errors.commission;
    delete errors.expenses;
    delete errors.fees;
    delete errors.invoices;
    delete errors.paymentComplete;
    delete errors.payments;
    delete errors.totalFees;
    delete errors.paymentDate;
    delete errors.review;
    delete errors.isMissingInvoiceData;

    console.log('error', errors)

    if (Object.keys(errors).length) return;
    this.doSubmit();
  };

  async populateUser() {
    const { data: user } = await getCurrentUser();
    if (user) this.setState({ user });
  }

  async populateClients() {
    const { data: clients } = await getClients();
    this.setState({ clients });
  }

  async populateOriginators() {
    const { data: originators } = await getOriginators();
    this.setState({ originators });
  }

  async populateUsers() {
    const { data: users } = await getUsers();
    this.setState({ users });
  }

  async populateTypes() {
    const { data: types } = await getTypes();
    this.setState({ types });
  }

  async populatePayments() {
    const { data: payments } = await getPayments();
    this.setState({ payments });
  }

  async populateInstrument(id) {
    const { data } = this.state;
    const { data: instrument } = await getInstrument(id);
    data.testimonies = instrument.testimony.items.filter(
      (t) => t.box?.numberBox === data.numberBox
    );
    this.setState({ instrument, data });
  }

  setTestimonies = (instruments) => {
    const { data } = this.state;
    const testimonies = [];
    for (let instrument of instruments) {
      if (instrument.testimony?.items) {
        instrument.testimony.items.forEach((t) => {
          if (t.box?.numberBox === data.numberBox) {
            testimonies.push({
              ...t,
              instrument: {
                _id: instrument._id,
                number: instrument.number,
              },
            });
          }
        });
      }
    }
    data.testimonies = testimonies;
    this.setState({ data });
  };

  async populateBox() {
    try {
      const boxId = this.props.match.params.id;
      const { data } = this.state;
      const { data: boxes } = await getBoxes();
      if (boxId === "new") {
        let maxNumberBox = 0;
        for (let { numberBox } of boxes) {
          if (numberBox > maxNumberBox) maxNumberBox = numberBox
        }
        maxNumberBox++
        data.numberBox = maxNumberBox
        this.setState({ data, boxes });
        return;
      }

      const { data: box } = await getBox(boxId);

      this.setState({
        client: box.client.name,
        data: this.mapToViewModel(box),
        payments: data.payments,
        boxes
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateConfigurations() {
    const { data: configurations } = await getConfigurations();
    let currencyUSD = configurations.filter((config) => config.name === "USD");
    currencyUSD = currencyUSD[0].value;
    this.setState({ currencyUSD, configurations });
  }

  async populateTotals() {
    const { data } = this.state;
    const sumFees =
      data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumFeesIVA =
      data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumExpenses =
      data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumExpensesIva =
      data.expenses.length > 0
        ? data.expenses
          .map((item) => (item.hasIva ? parseFloat(item.iva) : 0))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumInvoices =
      data.invoices.length > 0
        ? data.invoices
          .map((item) => parseFloat(item.amount))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumInvoicesIVA =
      data.invoices.length > 0
        ? data.invoices
          .map((item) => (item.iva ? parseFloat(item.iva) : 0))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumPayments =
      data.payments.length > 0
        ? data.payments
          .map((item) => parseFloat(item.amount))
          .reduce((prev, next) => prev + next)
        : 0;

    // console.log(sumFees, sumExpenses, sumInvoices, '-------------')
    const invoiceTotal = sumInvoices;
    const paymentTotal =
      sumInvoices + sumInvoicesIVA - sumPayments !== 0
        ? sumInvoices + sumInvoicesIVA - sumPayments
        : 0;
    const total = sumFees + sumExpenses;
    const totalIva = sumFeesIVA + sumExpensesIva;
    data.totalFees = sumFees;
    this.setState({
      data,
      sumFees,
      sumExpenses,
      sumExpensesIva,
      sumInvoices,
      sumInvoicesIVA,
      sumPayments,
      invoiceTotal,
      invoiceIVA: sumInvoicesIVA,
      paymentTotal,
      sumFeesIVA,
      total,
      totalIva,
    });
  }

  async populateStatus() {
    try {
      const data = { ...this.state.data };

      const {
        sumFees,
        sumExpenses,
        sumExpensesIva,
        sumInvoices,
        sumInvoicesIVA,
        sumPayments,
        sumFeesIVA,
      } = this.state;
      let name = "";
      const currentStatus = this.state.status;
      const Invoices = Number(parseFloat(sumInvoices).toFixed(2));
      const InvoicesIVA = Number(parseFloat(sumInvoicesIVA).toFixed(2));
      const Fees = Number(parseFloat(sumFees).toFixed(2));
      const FeesIVA = Number(parseFloat(sumFeesIVA).toFixed(2));
      const Expenses = Number(parseFloat(sumExpenses).toFixed(2));
      const ExpensesIva = Number(parseFloat(sumExpensesIva).toFixed(2));
      if (data.paymentComplete) {
        name = "Cobrado";
      } else if (
        data &&
        data.payments &&
        data.payments.length > 0 &&
        data.payments.some((p) => p.type === "Efectivo") &&
        data.statusId !== "5e9228fce5b40f5c30972094"
      ) {
        name = "En Autorización";
      } else if (
        data.invoices.some((invoice) => invoice.type === "Orden de compra")
      ) {
        name = "Parcialmente Facturado";
      } else if (sumInvoices === 0) {
        name = "Pendiente Facturar";
      } else if (
        sumPayments > 0 &&
        Number(
          parseFloat(
            Number(parseFloat(sumPayments).toFixed(2)) -
            (Invoices + InvoicesIVA)
          ).toFixed(2)
        ) === 0
      ) {
        name = "Cobrado";
      } else if (sumPayments > 0) {
        name = "Parcialmente Cobrado";
      } else if (
        sumInvoices > 0 &&
        Number(
          parseFloat(
            parseFloat(Number(Fees + FeesIVA + Expenses + ExpensesIva)).toFixed(
              2
            ) -
            (Invoices + InvoicesIVA)
          ).toFixed(2)
        ) === 0
      ) {
        name = "Facturado";
      } else if (sumInvoices > 0) {
        name = "Parcialmente Facturado";
      } else if (data.paymentDate.isBefore(moment(), "day")) {
        name = "En Mora";
      } else {
        name = "Pendiente Facturar";
      }

      const body = { name: name };
      const { data: status } = await getAllStatus(body);

      data.statusId = status ? status[0]._id : data.statusId;
      if (currentStatus !== "" && currentStatus !== name) {
        this.setState({ isStatusChanged: true });
      }
      this.setState({ data, status: name });
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      //   // this.props.history.replace("/not-found");
    }
  }

  async populateItems() {
    let { numbers, instrumentsMissing, data } = this.state;
    if (
      this.state.data.typeId === "5e922954e5b40f5c3097209c" ||
      this.state.data.typeId === "5ea3191dc7f4f52381c20a88"
    ) {
      //instrumento
      const { data: instruments } = await getInstruments();
      numbers = instruments.filter((i) => i.used === false && i.number >= 7389);
      if (data.items.length) this.setTestimonies(instruments);
    } else if (this.state.data.typeId === "5ea3191dc7f4f52381c20a88") {
      // Testimonio
      const { data: instruments } = await getInstruments();
      numbers = instruments;
      if (data.items.length) this.setTestimonies(instruments);
    } else if (this.state.data.typeId === "5e922942e5b40f5c3097209a") {
      const { data: valuations } = await getValuations();
      numbers = valuations;
    } else if (this.state.data.typeId === "5eaaf00b9b5a566466652b67") {
      const { data: mediations } = await getMediations();
      numbers = mediations;
    }
    this.setState({ numbers, instrumentsMissing });
  }

  async componentDidMount() {
    await Promise.all([
      this.populateTypes(),
      this.populateOriginators(),
      this.populateUsers(),
      this.populateClients(),
    ])
    await this.populateConfigurations()
    await this.populateUser()
    await this.populateBox()
    await this.populateItems()
    await this.populateTotals()
    await this.populateStatus()

    const { data, user } = this.state;
    let { isEditable, isNew } = this.state;
    isNew = this.props.match.params.id === "new";
    isEditable = !(
      user.role.name === "Abogado" &&
      !isNew &&
      data.userId !== user._id
    );
    this.setState({ isEditable, isNew });
  }

  mapToViewModel(box) {
    return {
      _id: box._id,
      numberBox: box.numberBox,
      typeId: box.type._id,
      items: box.items,
      invoices: box.invoices,
      clientId: box.client._id,
      originatorId: box.originator._id,
      userId: box.responsible._id,
      checkerId: box.checker ? box.checker._id : "",
      fees: box.fees,
      expenses: box.expenses,
      total: box.total,
      totalFees: box.totalFees,
      revenue: box.revenue,
      commission: box.commission,
      comChecker: box.comChecker ? box.comChecker : 0,
      paymentDate: box.paymentDate ? moment(box.paymentDate) : null,
      instructionInvoice: box.instructionInvoice,
      description: box.description,
      statusId: box.status._id,
      payments: box.payments,
      review: box.review,
      paymentComplete: box.paymentComplete,
      isMissingInvoiceData: box.isMissingInvoiceData,
      isExcludeFees: box.isExcludeFees,
    };
  }

  doSubmit = async () => {
    try {
      let { data, isStatusChanged, user } = this.state;
      data.user = user.name;

      if (!data.paymentDate) {
        delete data.paymentDate;
      }

      const payments = data.payments;

      if (
        payments.some((p) => p.type === "Efectivo") &&
        data.statusId !== "5e9228fce5b40f5c30972094"
      ) {

        if (this.state.user.role.name !== "Administrador") {
          const resp = await notificationBox(data._id, user._id);
          if (resp.data) {
            toast.success(
              "Se ha enviado una notificación al usuario responsable."
            );
            data.statusId = "65e258466f902a6f1312618b";
          }
        }

        if (this.state.user.role.name === "Administrador") {
          data.statusId = "5e9228fce5b40f5c30972094";
        }

      }

      const { data: box } = await saveBox(data);
      toast.success("La caja se guardo correctamente.");
      if (
        data.typeId === "5ea3191dc7f4f52381c20a88" ||
        data.typeId === "5e922954e5b40f5c3097209c" ||
        data.typeId === "5ea3191dc7f4f52381c20a88"
      ) {
        if (data._id === undefined) {
          await postBoxtestimonio({ boxId: data.numberBox });
        }
      }

      if (isStatusChanged) {
        this.props.history.push("/boxes/" + box._id);
        await this.populateBox();
      } else {
        this.props.history.push("/boxes");
      }
    } catch (ex) {
      toast.error(ex.response.data);
    }
  };

  handleInvoice = async (invoice) => {
    const data = { ...this.state.data };
    const invoices = data.invoices;
    const editData = invoices.findIndex((m) => m._id === invoice._id);
    if (editData < 0) {
      invoices.push(invoice);
    } else {
      invoices[editData] = invoice;
    }
    data.invoices = invoices;
    const sumInvoices =
      invoices.length > 0
        ? invoices
          .map((item) => parseFloat(item.amount) + parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0;
    const paymentTotal = sumInvoices - this.state.sumPayments;
    const invoiceTotal =
      this.state.sumFees + this.state.sumExpenses - sumInvoices;
    this.setState({ sumInvoices, invoiceTotal, paymentTotal, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  handleFee = async (fee) => {
    try {
      const data = { ...this.state.data };
      const fees = data.fees;
      const expenses = data.expenses;
      const editData = fees.findIndex((m) => m._id === fee._id);

      if (editData < 0) {
        fees.push(fee);
      } else {
        fees[editData] = fee;
      }

      const sumExpenses =
        expenses.length > 0
          ? expenses
            .map((item) => parseFloat(item.total))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumFees =
        fees.length > 0
          ? fees
            .map((item) => parseFloat(item.total))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumInvoices =
        data.invoices.length > 0
          ? data.invoices
            .map((item) => parseFloat(item.amount))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumTotal = sumFees + sumExpenses;
      //const sumTotal = ((data.fees.length > 0 ? data.fees.map(item => parseFloat(item.total)).reduce((prev, next) => prev + next) : 0 ) + (data.expenses.length > 0 ? data.expenses.map(item => parseFloat(item.total)).reduce((prev, next) => prev + next) : 0));
      const sumIva =
        (data.fees.length > 0
          ? data.fees
            .map((item) => parseFloat(item.iva))
            .reduce((prev, next) => prev + next)
          : 0) +
        (data.expenses.length > 0
          ? data.expenses
            .map((item) => parseFloat(item.iva))
            .reduce((prev, next) => prev + next)
          : 0);
      data.total = sumTotal + sumIva;
      data.totalFees = sumFees;
      //data.total = sumTotal + sumIva;
      const invoiceTotal = sumTotal;
      const invoiceIVA = sumIva;
      this.setState({
        sumFees,
        sumExpenses,
        sumInvoices,
        invoiceTotal,
        invoiceIVA,
        data,
      });
    } catch (ex) { }
  };

  handleExpense = async (expense) => {
    try {
      const data = { ...this.state.data };
      const expenses = data.expenses;
      const editData = expenses.findIndex((m) => m._id === expense._id);

      if (editData < 0) {
        expenses.push(expense);
      } else {
        expenses[editData] = expense;
      }

      const sumExpenses =
        expenses.length > 0
          ? expenses
            .map((item) => parseFloat(item.total))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumFees =
        data.fees.length > 0
          ? data.fees
            .map((item) => parseFloat(item.total))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumInvoices =
        data.invoices.length > 0
          ? data.invoices
            .map((item) => parseFloat(item.amount))
            .reduce((prev, next) => prev + next)
          : 0;
      const sumTotal = sumFees + sumExpenses;
      //const sumTotal = ((data.fees.length > 0 ? data.fees.map(item => parseFloat(item.total)).reduce((prev, next) => prev + next) : 0 ) + (data.expenses.length > 0 ? data.expenses.map(item => parseFloat(item.total)).reduce((prev, next) => prev + next) : 0));
      const sumIva =
        (data.fees.length > 0
          ? data.fees
            .map((item) => parseFloat(item.iva))
            .reduce((prev, next) => prev + next)
          : 0) +
        (data.expenses.length > 0
          ? data.expenses
            .map((item) => parseFloat(item.iva))
            .reduce((prev, next) => prev + next)
          : 0);
      data.total = sumTotal + sumIva;
      data.totalFees = sumFees;
      const invoiceTotal = sumTotal;
      const invoiceIVA = sumIva;
      this.setState({
        sumFees,
        sumExpenses,
        sumInvoices,
        invoiceTotal,
        invoiceIVA,
        data,
      });
    } catch (ex) { }
  };

  handlePayment = async (payment) => {
    const { data } = this.state;
    const payments = data.payments;
    const { data: originator } = await getOriginator(data.originatorId);
    const { data: user } = await getUser(data.userId);
    const editData = payments.findIndex((m) => m._id === payment._id);

    if (editData < 0) {
      payments.push(payment);
    } else {
      payments[editData] = payment;
    }

    const sumPayments =
      payments.length > 0
        ? payments
          .map((item) => parseFloat(item.amount))
          .reduce((prev, next) => prev + next)
        : 0;
    //const paymentTotal = (payments.length > 0 ? (data.invoices.map(item => parseFloat(item.amount)).reduce((prev, next) => prev + next) - sumPayments) : 0);
    const comResp = parseFloat(
      this.state.sumFees * (parseFloat(user.comRegular) / 100)
    );
    const comOrig = parseFloat(
      (this.state.sumFees - comResp) * (parseFloat(originator.percentage) / 100)
    );
    data.paymentDate = moment(payment.date);
    data.commission = data.checkerId ? comResp / 2 : comResp
    data.revenue = comOrig;
    if (data.checkerId)
      data.comChecker = comResp / 2;
    else
      data.comChecker = 0;

    this.setState({ sumPayments, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.data.checkerId !== this.state.data.checkerId) {
      const { data } = this.state;
      const payments = data.payments;
      const { data: originator } = await getOriginator(data.originatorId);
      const { data: user } = await getUser(data.userId);

      const sumPayments =
        payments.length > 0
          ? payments
            .map((item) => parseFloat(item.amount))
            .reduce((prev, next) => prev + next)
          : 0;
      const comResp = parseFloat(
        this.state.sumFees * (parseFloat(user.comRegular) / 100)
      );
      const comOrig = parseFloat(
        (this.state.sumFees - comResp) * (parseFloat(originator.percentage) / 100)
      );
      data.commission = data.checkerId ? comResp / 2 : comResp
      data.revenue = comOrig;
      if (data.checkerId)
        data.comChecker = comResp / 2;
      else
        data.comChecker = 0;

      this.setState({ sumPayments, data });
      await this.populateTotals();
      await this.populateStatus();
    }
  }

  handleCheckedItem = (item) => {
    const { data } = this.state;
    const fees = data.fees;
    const expenses = data.expenses;
    const editFee = fees.findIndex((m) => m._id === item._id);
    const editExpense = expenses.findIndex((m) => m._id === item._id);

    if (editFee !== -1) {
      fees[editFee] = item;
    } else {
      expenses[editExpense] = item;
    }

    this.setState({ data });
  };

  matchStateToTerm = (client, value) => {
    return client.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };

  filterInstruments = async (value) => {
    let { data: instruments } = await getInstruments();

    if (value) {
      instruments = instruments.filter(
        (instrument) =>
          instrument.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
    this.setState({ numbers: instruments });
  };

  handleAddClient = async () => {
    this.setState({ isLoadingClient: true })
    try {
      const response = await saveClient({
        name: this.state.client
      });
      this.setState({ data: {...this.state.data, clientId: response.data._id}, display: false });
      toast.success("El cliente se guardo correctamente.");
      this.setState({ isLoadingClient: false })
    } catch (e) {
      toast.error(`El cliente no se guardo, intentalo nuevamente. ${e.response?.data}`);
      this.setState({ isLoadingClient: false })
    }
  };

  handleTypeChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    let { numbers } = this.state;
    data[input.name] = input.value;
    if (input.name === "typeId" && input.value === "5e922954e5b40f5c3097209c") {
      const { data: instruments } = await getInstruments();
      numbers = instruments.filter((i) => i.used === false && i.number >= 7389);
      this.setTestimonies(instruments);
    } else if (
      input.name === "typeId" &&
      input.value === "5ea3191dc7f4f52381c20a88"
    ) {
      // Testimonio
      const { data: instruments } = await getInstruments();
      numbers = instruments;
      this.setTestimonies(instruments);
    } else if (
      input.name === "typeId" &&
      input.value === "5e922942e5b40f5c3097209a"
    ) {
      const { data: valuations } = await getValuations();
      numbers = valuations;
    } else if (
      input.name === "typeId" &&
      input.value === "5eaaf00b9b5a566466652b67"
    ) {
      const { data: mediations } = await getMediations();
      numbers = mediations;
    }

    this.setState({ numbers, data });
  };

  handleDeleteFee = async (fee) => {
    const { data } = this.state;
    const originalFees = data.fees;
    const fees = originalFees.filter((m) => m !== fee);
    data.fees = fees;

    const sumFees =
      data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumTotal =
      (data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0) +
      (data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0);
    const sumIva =
      (data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0) +
      (data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0);
    data.total = sumTotal + sumIva;
    const invoiceTotal = sumTotal - this.state.sumInvoices;
    this.setState({ sumFees, invoiceTotal, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  handleDeletExpense = async (expense) => {
    const { data } = this.state;
    const originalExpenses = data.expenses;
    const expenses = originalExpenses.filter((m) => m !== expense);
    data.expenses = expenses;

    const sumExpenses =
      data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0;
    const sumTotal =
      (data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0) +
      (data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.total))
          .reduce((prev, next) => prev + next)
        : 0);
    const sumIva =
      (data.fees.length > 0
        ? data.fees
          .map((item) => parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0) +
      (data.expenses.length > 0
        ? data.expenses
          .map((item) => parseFloat(item.iva))
          .reduce((prev, next) => prev + next)
        : 0);
    data.total = sumTotal + sumIva;
    const invoiceTotal = sumTotal - this.state.sumInvoices;
    this.setState({ sumExpenses, invoiceTotal, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  handleDeleteInvoice = async (invoice) => {
    const { data } = this.state;
    const originalInvoices = data.invoices;
    const invoices = originalInvoices.filter((m) => m !== invoice);
    data.invoices = invoices;

    const sumInvoices =
      data.invoices.length > 0
        ? data.invoices
          .map((item) => parseFloat(item.amount))
          .reduce((prev, next) => prev + next)
        : 0;
    const paymentTotal = sumInvoices - this.state.sumPayments;
    const invoiceTotal =
      this.state.sumFees + this.state.sumExpenses - sumInvoices;

    this.setState({ sumInvoices, invoiceTotal, paymentTotal, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  handleDeletePayment = async (payment) => {
    const { data } = this.state;
    const originalPayments = data.payments;
    const { data: originator } = await getOriginator(data.originatorId);
    const { data: user } = await getUser(data.userId);
    const payments = originalPayments.filter((m) => m !== payment);
    let comResp = 0;
    data.payments = payments;

    const sumPayments =
      data.payments.length > 0
        ? data.payments
          .map((item) => parseFloat(item.amount))
          .reduce((prev, next) => prev + next)
        : 0;
    //const paymentTotal = ((data.invoices.length > 0 ? (data.invoices.map(item => parseFloat(item.amount)).reduce((prev, next) => prev + next)) : 0) - sumPayments);

    if (this.state.data.typeId === "5e922954e5b40f5c3097209c") {
      comResp = parseFloat(sumPayments * (parseFloat(user.comRegular) / 100));
    } else if (this.state.data.typeId === "5e922942e5b40f5c3097209a") {
      comResp = parseFloat(sumPayments * (parseFloat(user.comValuation) / 100));
    }
    // const comOrig = originator.percentage === 100 ? parseFloat((sumPayments - comResp) * (parseFloat(originator.percentage) / 100)) : parseFloat(sumPayments * (parseFloat(originator.percentage) / 100));
    const comOrig = parseFloat(
      (sumPayments - comResp) * (parseFloat(originator.percentage) / 100)
    );
    data.paymentDate =
      payments.length > 0
        ? moment(
          originalPayments
            .filter((m) => m !== payment)
            .sort((a, b) => (a.date > b.date ? -1 : 1))[0].date
        )
        : moment();
    data.commission = data.checkerId ? comResp / 2 : comResp
    data.revenue = comOrig;
    if (data.checkerId)
      data.comChecker = comResp / 2;
    else
      data.comChecker = 0;

    this.setState({ sumPayments, data });
    await this.populateTotals();
    await this.populateStatus();
  };

  handleDateChange = (name, date) => {
    const { data } = this.state;
    data[name] = date;
    this.setState({ data });
  };

  matchNumberToTerm = (number, value) => {
    return (
      (number.number
        ? number.number
          .toString()
          .toLowerCase()
          .indexOf(value.toString().toLowerCase()) !== -1
        : number.numberValuation
          ? number.numberValuation
            .toString()
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1
          : number.numberAgreement
            .toString()
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1) ||
      (number.type
        ? number.type
          .toString()
          .toLowerCase()
          .indexOf(value.toString().toLowerCase()) !== -1
        : number.typeOfAsset
          ? number.typeOfAsset
            .toString()
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1
          : number.code
            .toString()
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1) ||
      (number.object
        ? number.object
          .toLowerCase()
          .indexOf(value.toString().toLowerCase()) !== -1
        : number.description
          ? number.description
            .toLowerCase()
            .indexOf(value.toString().toLowerCase()) !== -1
          : "")
    );
  };

  fakeRequest2 = async (value) => {
    const { data } = this.state;
    let { numbers, instrumentsMissing } = this.state;

    if (data.typeId === "5e922954e5b40f5c3097209c") {
      let { data: instruments } = await getInstruments();
      numbers = instruments;
      instrumentsMissing = instruments.filter(
        (i) => i.used === false && i.number >= 7389
      );
    } else if (data.typeId === "5e922942e5b40f5c3097209a") {
      const { data: valuations } = await getValuations();
      numbers = valuations;
    } else if (data.typeId === "5eaaf00b9b5a566466652b67") {
      const { data: mediations } = await getMediations();
      numbers = mediations;
    }

    if (value) {
      numbers = numbers.filter((number) =>
        this.matchNumberToTerm(number, value)
      );
      if (numbers.length === 1) {
        data.itemId = numbers[0]._id;
        this.setResponsible(numbers[0]);
      }
    }
    this.setState({ numbers, data, instrumentsMissing });
  };

  setResponsible = async (item) => {
    const { data } = this.state;
    try {
      const { data: user } = await getUser(item.responsibleId);
      if (user) data.userId = user._id;
    } catch {
      data.userId = "";
    }
    this.setState({ data });
  };

  onSelect = (selectedList, selectedItem) => {
    const { data } = this.state;
    data.items = selectedList;
    this.setState({ data });
  };

  onRemove = (selectedList, removedItem) => {
    const { data } = this.state;
    data.items = selectedList;
    this.setState({ data });
  };

  getColor = (status) => {
    let color = "";
    if (status === "Cancelado") {
      color = "badge-primary";
    } else if (status === "Cobrado") {
      color = "badge-primary";
    } else if (status === "En Mora") {
      color = "badge-late";
    } else if (status === "Facturado") {
      color = "badge-success";
    } else if (status === "Parcialmente Cobrado") {
      color = "badge-danger";
    } else if (status === "Parcialmente Facturado") {
      color = "badge-info";
    } else if (status === "Pendiente Facturar") {
      color = "badge-warning";
    } else if (status === "En Autorización") {
      color = "badge-warning";
    }
    return color;
  };

  onAddTestimony = async ({ number, responsible, instrument }) => {
    const { data } = this.state;
    data.testimonies.push({
      _id: new ObjectID(),
      number,
      responsible,
      instrument,
    });

    this.setState({ data });
  };

  onRemoveTestimony = (e, testimony) => {
    e.preventDefault();
    const { data } = this.state;
    data.testimonies = data.testimonies.filter((t) => t._id !== testimony._id);
    this.setState({ data });
  };

  clientsFiltered(client) {
    const { clients } = this.state;
    return clients.filter(({ name }) => name.toLowerCase().includes(client.toLowerCase()))
  }

  render() {
    const {
      data,
      user,
      types,
      numbers,
      originators,
      users,
      paymentTotal,
      configurations,
      currencyUSD,
      status,
      isEditable,
      isNew,
      total,
      totalIva,
      errors,
    } = this.state;
    const addItems = data.originatorId && data.userId ? true : false;

    return (
      <div>
        <form
          className="form mt-4"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <h3>
                <span className={`badge ${this.getColor(status)}`}>
                  {status}
                </span>
              </h3>
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("numberBox", "ID", "number")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderSelect(
                "typeId",
                "Tipo",
                types,
                this.handleTypeChange
              )}
            </div>
            <div className="col-md-4 mb-3">
              <Multiselect
                options={numbers} // Options to display in the dropdown
                selectedValues={data.items} // Preselected value to persist in dropdown
                onSelect={this.onSelect} // Function will trigger on select event
                onRemove={this.onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Número"
                avoidHighlightFirstOption={true}
                emptyRecordMsg="No hay opciones disponibles"
                selectionLimit={
                  data.typeId === "5ea3191dc7f4f52381c20a88" ? 1 : -1
                }
              />
              {errors["items"] && (
                <div className="alert alert-danger">{errors["items"]}</div>
              )}
              {(data.typeId === "5ea3191dc7f4f52381c20a88" ||
                data.typeId === "5e922954e5b40f5c3097209c") &&
                data.items.length > 0 && (
                  <Testimonies
                    responsibles={users}
                    instruments={data.items || []}
                    testimonies={data.testimonies || []}
                    onAddTestimony={this.onAddTestimony}
                    onRemoveTestimony={this.onRemoveTestimony}
                    type={
                      data.typeId === "5ea3191dc7f4f52381c20a88"
                        ? "Testimonio"
                        : "Instrumento"
                    }
                  />
                )}
            </div>
          </div>

          <div className="form-row">

            <div className="col-12 col-md mb-3">
              <Autocomplete
                inputProps={{
                  id: "clientId",
                  name: "clientId",
                  className: "form-control",
                  placeholder: "Cliente",
                }}
                wrapperStyle={{ position: "", background: "#b8daff" }}
                value={this.state.client}
                items={this.clientsFiltered(this.state.client)}
                getItemValue={(item) => item.name}
                selectOnBlur={true}
                onSelect={(value, item) => {
                  data.clientId = item._id;
                  data.originatorId = item?.originator?._id || "";
                  this.setState({ client: value, data, disabled: true });
                }}
                onChange={(event, value) => {
                  const filtered = this.clientsFiltered(value)
                  let display = false;
                  data.clientId = '';
                  if (filtered.length === 0) {
                    display = true
                  } else if (filtered.length === 1) {
                    const [ oneClient ] = filtered;
                    data.clientId = oneClient._id;
                  }
                  this.setState({ client: value, data, display });
                }}
                renderMenu={(children) => (
                  <div className="menu">{children}</div>
                )}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "item-highlighted" : ""
                      }`}
                    key={item._id}
                  >
                    {item.name}
                  </div>
                )}
                renderInput={(props) => (<>
                  <input {...props} />
                  <label htmlFor="clientId">Cliente</label>
                </>)}
                wrapperProps={{ className: 'form-label-group' }}
              />
              {user?.role?.actions.some(({method}) => method === "add-client") && (
                  <button
                    disabled={this.state.isLoadingClient}
                    onClick={this.handleAddClient}
                    type="button"
                    style={{
                      display: this.state.display ? 'block': 'none',
                      float: "right",
                      position: "relative",
                      bottom: "30px",
                      borderRadius: "20px",
                      backgroundColor: "#28a745",
                      borderColor: "#28a745",
                      color: "#fff",
                      right: "5px",
                      border: 'none',
                      fontSize: '12px',
                      padding: '4px 12px',
                      outline: '3px solid #ccc',
                      zIndex: '1',
                    }}
                  >
                    crear cliente
                  </button>
                )}
              {this.state.errors && this.state.errors["clientId"] && (
                <div className="alert alert-danger">
                  {this.state.errors["clientId"]}
                </div>
              )}
            </div>

            <div className="col-12 col-md mb-3">
              {this.renderSelect(
                "originatorId",
                "Originador",
                originators,
                null,
                isNew
                  ? false
                  : !(
                    user &&
                    user.role &&
                    user.role.actions.some(
                      (a) => a.method === "edit-originator"
                    )
                  )
              )}
            </div>

            <div className="col-12 col-md mb-3">
              {this.renderSelect("userId", "Responsable", users)}
            </div>

            <div className="col-12 col-md mb-3">
              {this.renderSelect(
                "checkerId",
                "Revisor",
                users.filter((u) => u.role.name !== "Junior")
              )}
            </div>

          </div>

          <div className="form-row">
            <div className="col-md-6">
              {this.renderInput("instructionInvoice", "Instrucción Factura")}
            </div>
            <div className="col-md-6">
              {this.renderInput("description", "Descripción")}
            </div>
          </div>
          <Calculator key="calculator" element={data} show={true} />

          <div className="form-row">
            { (user?.role?.actions.some((a) => a.method === "fees-box") && this.state.boxes.length > 0) && (
                <div className="col-md-12 mb-3 table-pr">
                  <Fees
                    user={user}
                    onFee={this.handleFee}
                    fees={data.fees}
                    usd={currencyUSD}
                    addItems={addItems}
                    configurations={configurations}
                    onDelete={this.handleDeleteFee}
                    boxes={this.state.boxes}
                  />
                </div>
            ) }
            { (user?.role?.actions.some((a) => a.method === "expenses-box") && this.state.boxes.length > 0) && (
                <div className="col-md-12 mb-3 table-lr table-pr">
                  <Expenses
                    user={user}
                    onExpense={this.handleExpense}
                    expenses={data.expenses}
                    usd={currencyUSD}
                    addItems={addItems}
                    onDelete={this.handleDeletExpense}
                    boxes={this.state.boxes}
                  />
                </div>
            ) }
          </div>
          { user?.role?.actions.some((a) => a.method === "invoices-box") && (
              <div className="form-row">
                <div className="col-md-12 mb-3 table-pr">
                  <Invoices
                    numberBox={data.numberBox}
                    user={user}
                    onInvoice={this.handleInvoice}
                    usd={currencyUSD}
                    invoices={data.invoices}
                    fees={data.fees}
                    expenses={data.expenses}
                    total={total}
                    iva={totalIva}
                    checkedItem={this.handleCheckedItem}
                    onDelete={this.handleDeleteInvoice}
                    onUploadDocument={(uid, document) => {
                      const invoicePosition = this.state.data.invoices.findIndex(({ _id }) => _id === uid)
                      data.invoices[invoicePosition].file = document
                      this.setState({ data })
                    }}
                    onDeleteDocument={(uid) => {
                      const invoicePosition = this.state.data.invoices.findIndex(({ _id }) => _id === uid)
                      data.invoices[invoicePosition].file = null
                      this.setState({ data })
                    }}
                    onLoadingDocument={(uid, isLoading) => {
                      const invoicePosition = this.state.data.invoices.findIndex(({ _id }) => _id === uid)
                      data.invoices[invoicePosition].isLoading = isLoading
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
            )}
          {/* Manager Admin */}
          {user &&
            user.role &&
            user.role.actions.some((a) => a.method === "payments-box") && (
              <div className="form-row">
                <div className="col-md-12 mb-3 table-pr">
                  <Payments
                    user={user}
                    onPayment={this.handlePayment}
                    payments={data.payments}
                    total={paymentTotal}
                    onDelete={this.handleDeletePayment}
                  />
                </div>
              </div>
            )}
          <div className="row">
            <div className="col">
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isExcludeFees"
                  id="isExcludeFees"
                  checked={data.isExcludeFees}
                  onChange={(event) => {
                    data.isExcludeFees = event.target.checked;
                    this.setState({ data });
                  }}
                />
                <label className="form-check-label" htmlFor="isExcludeFees">
                  Excluir de comisiones
                </label>
              </div>
              {this.renderInput(
                "excludeFeesNotes",
                "Notas",
                "text",
                false,
                !data.isExcludeFees
              )}
            </div>
          </div>
          <div className="form-row">
            <table className="col-md-12 table table-borderless text-center">
              <thead>
                <tr className="table-primary">
                  <th scope="col">Total</th>
                  <th scope="col">Honorarios netos</th>
                  <th scope="col">Comisión originador</th>
                  <th scope="col">Comisión responsable</th>
                  <th scope="col">Comisión revisor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currencyFormat(data.total)}</td>
                  <td>{currencyFormat(data.totalFees)}</td>
                  <td>{currencyFormat(data.revenue)}</td>
                  <td>{currencyFormat(data.commission)}</td>
                  <td>{currencyFormat(data.comChecker)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            {data.invoices.length > 0 && (
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="complete"
                    id="complete"
                    checked={data.paymentComplete}
                    onChange={(event) => {
                      data.paymentComplete = event.target.checked;
                      this.setState({ data });
                      this.populateStatus();
                    }}
                  />
                  <label className="form-check-label" htmlFor="complete">
                    Pago completo
                  </label>
                </div>
              </div>
            )}
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isMissingInvoiceData"
                  id="isMissingInvoiceData"
                  checked={data.isMissingInvoiceData}
                  onChange={(event) => {
                    data.isMissingInvoiceData = event.target.checked;
                    this.setState({ data });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="isMissingInvoiceData"
                >
                  Faltan datos de facturación
                </label>
              </div>
            </div>
          </div>

          {user &&
            user.role &&
            user.role.actions.some((a) => a.method === "save-box") &&
            user.role && user.role.name === "Administrador" &&
            isEditable && (
              <button
                className="btn btn-primary float-right"
                type="submit"
              >
                Guardar
              </button>
            )}
          {user &&
            user.role && user.role.name !== "Administrador" &&
            user.role.actions.some((a) => a.method === "save-box") &&
            isEditable && (
              <button
                className="btn btn-primary float-right"
                type="submit"
              >
                Guardar
              </button>
            )}
        </form>
      </div>
    );
  }
}

export default BoxForm;
